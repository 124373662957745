import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
// import Custom Components
import Header from "./common/header";
import Footer from "./common/footer";
// import Utils
import { preventProductDefault, removePreventProductDefault, stickyHeaderHandler } from "../utils";
import { addAltForChatImages, checkVersion } from "../utils";
import { useLocation } from "react-router-dom";

function App(props) {
	const [visibility, setVisibility] = useState(false);
	const [container] = useState("container");
	const [prevPath] = useState("");
	const location = useLocation();
	let notHeaderFooterRoutes = [
		"/login",
		"/register",
		"/reset-password",
		"/password/reset",
		"/trader/trader-admin/listing",
		"/trader/trader-admin/listing/update",
		"/trader/trader-admin/product-detail",
		"/trader/trader-admin",
		"/trader/trader-admin/price-listing",
		"/trader/trader-admin/listing-status",
		"/trader/trader-admin/private-listing",
		"/trader/trader-admin/offer-bid-listing",
		"/trader/trader-admin/ads-management",
		"/trader/trader-admin/featured-product",
		"/trader/trader-admin/bulk-edit",
		"/trader/trader-admin/bulkEdit",
		"/trader/trader-admin/chat",
		"/promo/mission-solar-24MAA",
		"/trader/trader-admin/dashboard",
		// "/shop/product-checkout",
		"/shop/checkout",
	];
	useEffect(() => {
		setTimeout(() => {
			document.querySelector("body").classList.add("loaded");
			document.querySelector("#root").classList.add("loaded");
		}, 200);
	});

	useEffect(() => {
		//for chat library image tag alt attribute
		addAltForChatImages();
		// // set sticky header
		stickyHeaderHandler();
		window.addEventListener("scroll", stickyHeaderHandler, true);
		// prevent product thumb icons
		preventProductDefault();

		// Optional: Check version periodically (every 5 minutes) and clear cashed if required
		// const interval = setInterval(checkVersion, 5 * 60 * 1000);
		// const interval = setInterval(checkVersion, 5000);

		return () => {
			// clearInterval(interval);
			window.removeEventListener("scroll", stickyHeaderHandler);

			// remove listeners of prevent product
			removePreventProductDefault();
		};
	}, []);

	useEffect(() => {
		// execute your function here every time the URL changes
		if (!notHeaderFooterRoutes.includes(window.location.pathname.toString()) && !window.location.pathname.includes("/trader/trader-admin/product-detail")) {
			setVisibility(true);
		} else {
			setVisibility(false);
		}
	}, [location.pathname]);

	useEffect(() => {
		const targetUrls = ["/trader/trader-admin", "/trader/chat/seller", "/trader/chat/buyer"];
		const currentUrl = location.pathname; // Ensure you're using `useLocation` for consistency
		const isUrlMatched = targetUrls.some((url) => currentUrl.includes(url));
	
		const handleDemandHubUpdate = () => {
		  const handleDemandHub = document.querySelector("#demandhub-webchat-widget-root");
		  if (handleDemandHub) {
			if (isUrlMatched) {
			  handleDemandHub.classList.add("d-none");
			} else {
			  handleDemandHub.classList.remove("d-none");
			}
		  }
		};
	
		// Execute immediately in case the DOM is ready
		handleDemandHubUpdate();
	
		// Also add a delay to ensure it works for dynamically loaded elements
		const timeoutId = setTimeout(handleDemandHubUpdate, 200);
	
		return () => clearTimeout(timeoutId);
	  }, [location]);

	return (
		<div className="page-wrapper">
			{visibility && (
				<Header
					container={container}
					urls={prevPath}
				/>
			)}

			{props.children}

			{visibility && <Footer container={container} />}

			<ToastContainer
				autoClose={3000}
				className="toast-container"
			/>
		</div>
	);
}
export default App;
